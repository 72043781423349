.faq-row {
    border-bottom: 1px solid #cdcdcd;
    .faq-head {
        padding: 40px 0;
        cursor: pointer;
        b {
            font-weight: 900;
            font-size: 72px;
            line-height: 1;
            color: #000000;
            opacity: 0.07;
        }
        h4 {
            margin: 0;
            font-weight: bold;
            font-size: 24px;
            line-height: 35px;
            color: #353535;
            padding-left: 40px;
        }
    }
    .faq-body {
        padding-bottom: 40px;
        p {
            font-weight: 500;
            font-size: 16px;
            line-height: 28px;
            color: #858585;
        }
    }
}

@media (max-width: 991px) {
    .faq-row {
        .faq-head {
            b {
                font-size: 50px;
            }
            h4 {
                font-size: 20px;
                padding-left: 20px;
            }
        }
    }
}
