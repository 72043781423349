.aboutPage {
    .aboutLayerOne {
        margin-bottom: 15rem;
        h1 {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 900;
            font-size: 48px;
            line-height: 59px;

            color: #353535;
        }
        h2 {
            font-weight: bold;
            font-size: 36px;
            line-height: 44px;
            color: #353535;
            margin-bottom: 1.5rem;
        }
        p {
            font-weight: 500;
            font-size: 16px;
            line-height: 28px;
            /* or 175% */

            color: #858585;
            margin-bottom: 1.75rem;
        }
        a {
            svg {
                color: #353535;
            }
            &:hover {
                svg {
                    color: #000;
                }
            }
        }
    }

    .aboutLayerTwo {
        margin-bottom: 15rem;
        h1 {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 900;
            font-size: 48px;
            line-height: 59px;

            color: #353535;
        }
        .principles-box {
            padding-right: 4rem;
            b {
                font-weight: 900;
                font-size: 96px;
                line-height: 117px;
                color: #000000;
                opacity: 0.07;
            }
            h4 {
                font-weight: bold;
                font-size: 24px;
                line-height: 35px;
                color: #353535;
                margin-top: -50px;
            }
            p {
                font-weight: 500;
                font-size: 16px;
                line-height: 28px;
                color: #858585;
            }
        }
    }
}

@media (max-width: 1199px) {
    .aboutPage {
        img {
            max-width: 100%;
        }
        .aboutLayerOne {
            margin-bottom: 8rem;
            h1 {
                font-size: 30px;
                line-height: normal;
            }
            h2 {
                font-size: 26px;
                line-height: normal;
            }
            p {
                font-size: 14px;
            }
        }

        .aboutLayerTwo {
            margin-bottom: 10rem;
            h1 {
                font-size: 30px;
                line-height: normal;
            }
            h2 {
                font-size: 26px;
                line-height: normal;
            }
            p {
                font-size: 14px;
            }
        }
    }
}

@media (max-width: 991px) {
    .aboutPage {
        img {
            width: 100%;
        }
    }
}
@media (max-width: 585px) {
    .aboutPage {
        .aboutLayerTwo h1 {
            font-size: 20px;
        }
    }
}
