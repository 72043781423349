footer {
    .menu {
        background-color: #fafafa;
    }
    nav {
        padding: 30px 0;

        ul {
            li {
                a {
                    display: block;
                    padding: 5px 15px;
                    color: #353535;
                    font-weight: 500;
                    font-size: 18px;
                }
            }
        }
    }
    p {
        margin: 0;
        padding: 16px 0;
    }
}

@media (max-width: 991px) {
    footer {
        .menu {
            nav {
                padding: 0;
                ul {
                    li {
                        a {
                            font-size: 75%;
                        }
                    }
                }
            }
        }
        p {
            font-size: 75%;
        }
    }
}
